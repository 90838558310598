<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <!-- Table Container Card -->
    

    
    <!-- Table Container Card -->
      <!-- <b-card no-body class="mb-0"> -->
          <!-- {{ items }} -->

          <div class="kanban-wrapper mt-3">
                <div class="px-1 kanban-card" v-for="column in items" :key="column._id">
                  <p class="h5 text-white">{{ column.name }}</p>
                    <div class="card">
                        <div class="p-1 card-body rounded" style="height: 415px; overflow: auto;">
                          
                          <!-- {{ column._id }} -->
                          <!-- card -->

                          <draggable :list="column.inventory_user" 
                              :animation="200" 
                              ghost-class="ghost-card" 
                              group="user" 
                              :move="checkMove"
                              @end="handleDragEnd(column._id, column.inventory_user)"
                              style="min-height:325px;"
                            >
                              <!-- :class="this.form.email_verify == 'no' && this.showVerifyEmail ? 'email_profile_col' : 'col-xl-4'" -->

                              <div v-for="(user) in column.inventory_user" :key="user._id" class="bg-light rounded border border-white mt-1">

                                  <div class="p-1" style="padding-bottom: 35px !important;">
                                    <!-- {{ column._id }} -->
                                      <div class="text-secondary">
                                        <span class="font-weight-bold">Project Site: </span>
                                        <span v-b-tooltip.hover.v-primary.top :title="getFromAsgSite(user.sites)" v-if="user.sites.length > 0 && getFromAsgSite(user.sites).split(',').length > 3">
                                          {{ getFromAsgSite2(user.sites)[0] }}
                                          <b-badge v-if="getFromAsgSite2(user.sites)[1] != ''" pill variant="primary"> +{{ getFromAsgSite2(user.sites)[1] }} </b-badge>
                                        </span>
                                        <span v-if="user.sites.length > 0 && getFromAsgSite(user.sites).split(',').length <= 3">
                                          {{ getFromAsgSite2(user.sites)[0] }}
                                        </span>
                                        <span v-if="user.sites.length == 0">{{ 'N/A' }}</span>
                                      </div>

                                      <div class="text-secondary">
                                        <span class="font-weight-bold">User Name: </span> <span class="wordBreak">{{ user.user.full_name | capitalize }}</span>
                                      </div>

                                      <div class="text-secondary mt-1">
                                        <div class="float-left"> <feather-icon icon="PhoneIcon" /> {{ user.user.phone_number }}</div>
                                        <div class="float-right"><feather-icon icon="CalendarIcon" /> {{ sitedateTime(user.created_at,'DD MMM YYYY', '') }}</div>
                                      </div>

                                  </div>

                              </div>
                            </draggable>
                            
                            <b-button class="mt-1" block variant="warning" :to="{ name: 'add-inventory-area-user', params: { area_id: column._id }}">Add User</b-button>
                      </div>
                    </div>
                </div>
            </div>

      <!-- </b-card> -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BBreadcrumb,BCardBody, VBTooltip, BFormCheckbox, BCardFooter
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'

export default {
  components: {
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    BBreadcrumb,
    draggable,
    BCardFooter
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  data() {
    return {

        tableColumns: [
          
          { key: 'info', label: 'INFO', sortable: false, thStyle: { width: '10%' } },
          { key: 'name', label: 'Area Name', sortable: true , thStyle:  {width: '25%'}},
          { key: 'status', label: 'Status', sortable: true , thStyle:  {width: '15%'}},
          { key: 'created_at', label: 'Added On', sortable: true , thStyle:  {width: '20%'}},
          { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '30%'}},
        ],
        
        items:[],
        isSortDirDesc: false,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
       
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,

        area_user_data: null,
        source_area_id: null,
        destination_area_id: null,
    }
  },
  methods : {

    checkMove(e) {
      
      this.area_user_data = e.draggedContext.element;
      // console.log("+++++++++++++++++++++ destination" , this.area_user_data);
        // console.table(yourObject);

        // const draggedUser = e.draggedContext.element;
        // const sourceColumnId = draggedUser.inventory_area;
        // return true; // Allow the move to happen
    },

    handleDragEnd(sourceColumnId, sourceColumn) {
      return this.$store.dispatch(POST_API, {
        data:{
          data: this.items
        },
        api: '/api/inventory-area-user-update-kanban'
      })
      .then(() => {
        if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
        } else {
            // this.dataList();
        }
      });
    },

    /* handleDragEnd(sourceColumnId, sourceColumn) {
      this.source_area_id = sourceColumnId;

      

      for(var i = 0; i < this.items.length; i++){
        if(this.items[i].inventory_user.length > 0){
          for(var j = 0; j < this.items[i].inventory_user.length; j++){
            if(sourceColumnId == this.items[i].inventory_user[j].inventory_area){
              // console.log(this.items[i].inventory_user[j]);
              this.destination_area_id = this.items[i]._id;

              // j = this.items[i].inventory_user.length;
              // i = this.items[i].length;
            }
          }
        }

        if(this.source_area_id != null && this.destination_area_id != null){

          if(this.source_area_id != this.destination_area_id){
            return this.$store.dispatch(POST_API, {
                data:{
                  source_area_id : this.source_area_id,
                  destination_area_id : this.destination_area_id,
                  data_id : this.area_user_data._id
                },
                api: '/api/inventory-area-user-update-kanban'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                } else {
                    // this.dataList();
                }
            });
          }

        }
      }



        // Iterate through all columns to find the destination column
        // this.columns.forEach((destinationColumn) => {
        //     if (destinationColumn !== sourceColumn) {
        //     // Log the destination column tasks
        //         console.log(`Destination Column (${destinationColumn.title}): `, destinationColumn.tasks);
        //     }
        // });
        // Log the source column tasks

        // console.log(`Source Column (${sourceColumnId}): `, sourceColumn);

        // // Iterate through all columns to find the destination column
        // this.items.forEach((destinationColumn) => {
        //   if (destinationColumn._id !== sourceColumnId) {
        //     // Log the destination column tasks
        //     console.log(`Destination Column (${destinationColumn._id}): `, destinationColumn.inventory_user);

        //     // Here, you can implement your logic to identify the moved user
        //     const movedUser = destinationColumn.inventory_user.find((user) => user._id === sourceColumn[0]._id);
        //     if (movedUser) {
        //       console.log(`User moved from ${sourceColumnId} to ${destinationColumn._id}`, movedUser);
        //       // Implement your further logic here
        //     }
        //   }
        // });
    }, */
    
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Inventory'
      },{
        to:null,
        text: 'Settings'
      },{
        to:null,
        text: 'Inventory Site Area',
        active:true
      }];
      return item;
    },
    
    dataList(){
      return this.$store.dispatch(POST_API, {
          data:{
            page: this.currentPage,
            keyword: this.searchQuery,
            rowsPerPage:this.perPage,
            sortBy:this.sortBy,
            sortDirection:this.isSortDirDesc,
          },
          api: '/api/inventory-area-kanban-list'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.items = this.$store.getters.getResults.data;
              // this.totalRecords = this.$store.getters.getResults.data.total;
              // this.perPage = this.$store.getters.getResults.data.limit;
              // this.from = this.$store.getters.getResults.data.from
              // this.to = this.$store.getters.getResults.data.to
              return this.items;
          }
      });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },
    
    statusChange(item){

      if (item.status == 'active' && item.subuser == true ) {
              
          var msg     = 'Some User linked with this area. Are you sure want to inactivate this record?';
          var message = 'Area Inactivated Successfully.';

      } else if (item.status == 'active' && item.subuser == false) {
        var msg = 'Are you sure want to inactivate this record?';
        var message = 'Area Inactivated Successfully.';
      }
      else {
        var msg = 'Are you sure want to activate this record?';
        var message = 'Area Activated Successfully.';
      }
      //console.log('aaabbbbbbb');

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: item._id,
                          status:item.status
                        },
                    api:"/api/change-inventory-area-status",
                      })
                      .then(() => {
                        console.log(this.$store.getters.containsErrors,'aaaaaa');  
                        if (this.$store.getters.containsErrors) {

                              //this.error_message = this.$store.getters.getErrors;
                              this.errorAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
             
          })
    },
    deleteItem(item){
      var msg = 'Are you sure want to delete this record?';
      var message = 'Area Deleted Successfully.';


      Swal.fire({
        title: 'Please Confirm',
        text: msg,
        icon: 'warning',
        position: 'top-center',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
        .then(result => {
          if (result.value) {
            return this.$store.dispatch(POST_API, {
              data: {
                id: item._id,
                status: 'deleted'
              },
              api: "/api/change-inventory-area-status",
            })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {

                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                  })

                  this.$refs.refUserListTable.refresh();
                }
              });
          }
        })
        .catch(err => {

        })
      
    },
    getFromAsgSite(site) {
      if (site.length == 0) {
        return 'N/A';
      } else {
        var arr = [];
        for (var i = 0; i < site.length; i++) {
          if (this.$store.getters.currentUser.role == 'admin') {
            arr.push(site[i].site_name);
          } else if (this.$store.getters.currentUser.om_sites.indexOf(site[i]._id) >= 0) {
            arr.push(site[i].site_name);
          }
        }

        return arr.join(', ');

      }
    },
    getFromAsgSite2(site) {
      if (site.length == 0) {
        return 'N/A';
      } else {
        var arr = [];
        for (var i = 0; i < site.length; i++) {
          if (this.$store.getters.currentUser.role == 'admin') {
            arr.push(site[i].site_name);
          } else if (this.$store.getters.currentUser.om_sites.indexOf(site[i]._id) >= 0) {
            arr.push(site[i].site_name);
          }
        }

        if (arr.length > 3) {
          return [arr[0] + ', ' + arr[1] + ', ' + arr[2], (arr.length - 3)]
        } else {
          return [arr.join(', '), ''];
        }

      }
    },
    generatePDF(){
      var baseurl = null;
      baseurl = process.env.VUE_APP_SERVER_URL + "/api/inventory-area-users-pdf?organization=" + this.$store.getters.currentUser.organization+"&keyword="+ (this.searchQuery == null ? '' : this.searchQuery);
      window.open(baseurl, '_blank');
    }
  },
  mounted(){
    this.dataList();    
  }
  
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
